:root {
	--feeds-post: #1786c2;
	--feeds-sharecare: #80cc28;
	--feeds-host: #e81067;
	--feeds-calendar: #fdb620;
	--feeds-survey: #19619a;

	--feeds-notification: 151, 71, 255;

	--feeds-black: 60, 57, 52;
	--feeds-bg: #fafafa;
	--feeds-formBody: #f9f8f8;
	--feeds-sidebar: #6d6b67;

	--shadow-feedBox: -1px -1px 4px 0px rgba(0, 0, 0, 0.25),
		1px 1px 4px 0px rgba(0, 0, 0, 0.25);
	--shadow-feedMenu: -1px -1px 8px 0px rgba(0, 0, 0, 0.05),
		1px 1px 8px 0px rgba(0, 0, 0, 0.05);
	--shadow-feedNotification: -1px -1px 8px 0px rgba(var(--feeds-notification), 0.5),
		1px 1px 8px 0px rgba(var(--feeds-notification), 0.5);
}
