:root {
	--dg-color-white: 255 255 255;
	--dg-color-black: 60 57 52;
	--dg-color-post: 229 57 144;
	--dg-color-help: 1 114 187;
	--dg-color-sharecare: 36 166 32;
	--dg-color-host: 219 15 16;
	--dg-color-survey: 50 49 143;
	--dg-color-calendar: 253 182 32;
}
