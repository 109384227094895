/* css for decidim_feeds */

@import '_variables.scss';

p,
a {
	word-break: break-word;
	overflow-wrap: break-word;
}

#posts__post_newElement {
	&.open {
		--tw-ring-color: var(--feeds-sidebar);
		display: flex;
	}
	.posts__post_newElement_modal_frame {
		--tw-ring-color: theme('colors.white');
	}
	.category-selection {
		button {
			&:hover {
				background: white;
				color: theme('colors.black');
			}
			&[data-category='post'] {
				border-color: var(--feeds-post);
				&:focus {
					outline-color: var(--feeds-post);
				}
				&:hover,
				&.active {
					outline: 2px solid var(--feeds-post);
					outline-offset: 2px;
				}
			}
			&[data-category='host'] {
				border-color: var(--feeds-host);
				&:focus {
					outline-color: var(--feeds-host);
				}
				&:hover,
				&.active {
					outline: 2px solid var(--feeds-host);
					outline-offset: 2px;
				}
			}
			&[data-category='sharecare'] {
				border-color: var(--feeds-sharecare);
				&:focus {
					outline-color: var(--feeds-sharecare);
				}
				&:hover,
				&.active {
					outline: 2px solid var(--feeds-sharecare);
					outline-offset: 2px;
				}
			}
			&[data-category='survey'] {
				border-color: var(--feeds-survey);
				&:focus {
					outline-color: var(--feeds-survey);
				}
				&:hover,
				&.active {
					outline: 2px solid var(--feeds-survey);
					outline-offset: 2px;
				}
			}
			&[data-category='calendar'] {
				border-color: var(--feeds-calendar);
				&:focus {
					outline-color: var(--feeds-calendar);
				}
				&:hover,
				&.active {
					outline: 2px solid var(--feeds-calendar);
					outline-offset: 2px;
				}
			}
		}
	}
	form {
		&.open {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			opacity: 1;
			transform: scale(1);
			@media (prefers-reduced-motion: no-preference) {
				transition: transform 0.2s, opacity 0.2s;
			}
		}
		label {
			font-size: 1rem;
		}
		input,
		textarea {
			margin-block-start: 0;
		}
		.posts__post_newElement_Xtra {
			&.open {
				display: block;
				opacity: 1;
				transform: scale(1);
				@media (prefers-reduced-motion: no-preference) {
					transition: transform 0.2s, opacity 0.2s;
				}
			}
		}
		.posts__post_newElement_Media {
			svg {
				width: 24px;
				height: auto;
			}
			.active-uploads {
				margin-top: 0;
				padding-bottom: 0.5rem;
			}
			button {
				margin-top: 0;
			}
		}
	}
}

.feeds__container {
	.feeds__body {
		.posts__post {
			.card__grid-text {
				word-break: break-word;
				overflow-wrap: break-word;
				p {
					color: black;
				}
				a {
					text-decoration: underline;
					transition: color 0.2s;
					@media (prefers-reduced-motion: no-preference) {
						transition: color 0.2s;
					}
					&:hover {
						color: rgba(var(--feeds-notification), 1);
						text-decoration: none;
					}
				}
			}
			&[data-content] {
				--tw-shadow: var(--shadow-feedBox);
				--tw-shadow-colored: var(--shadow-feedBox);
			}
			&[data-content='post'] {
				--tw-ring-color: var(--feeds-post);
			}
			&[data-content='sharecare'] {
				--tw-ring-color: var(--feeds-sharecare);
			}
			&[data-content='host'] {
				--tw-ring-color: var(--feeds-host);
				&.host-highlight {
					background-color: var(--feeds-host);
					font-weight: 500;
					.content-block__description {
						color: white;
						button {
							color: white;
						}
					}
					.card__grid-metadata {
						.posts__post_actions_submenu {
							button.openButton,
							button.openButton span {
								color: white;
							}
							&:has(ul:empty) {
								display: none;
							}
						}
					}
				}
			}
			&[data-content='survey'] {
				--tw-ring-color: var(--feeds-survey);
			}
			&[data-content='calendar'] {
				--tw-ring-color: var(--feeds-calendar);
			}
			.card__grid-metadata {
				.author {
					margin-right: auto;
					flex-shrink: 1;
				}
			}
			.posts__post_gallery {
				li {
					transition: opacity 0.5s ease;
					opacity: 0;
					display: none;
					&.active {
						opacity: 1;
						display: block;
					}
					@supports not (aspect-ratio: 4 / 3) {
						&::before {
							float: left;
							padding-top: 75%;
							content: '';
						}

						&::after {
							display: block;
							content: '';
							clear: both;
						}
					}
				}
			}
			.posts__post_comments {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				#comments {
					.comments__header {
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						height: 2rem;
						align-items: center;
						margin-bottom: 0;
						h2 {
							display: flex;
							font-size: 0.875rem; /* equivalent to text-sm */
							font-weight: 500; /* equivalent to font-medium */
							gap: 0.25rem; /* equivalent to gap-1 */
							cursor: pointer;
							svg {
								width: 14px;
								height: auto;
								fill: rgba(var(--feeds-black), 1);
							}
						}
					}
					.comment-order-by {
						display: none;
					}
					.comment-threads,
					.add-comment:not(.comment__additionalreply) form.new_comment {
						height: 0;
						visibility: hidden;
						@media (prefers-reduced-motion: no-preference) {
							transition: transform 0.2s;
						}
					}
					.comment-threads {
						.comment-thread {
							.comment__header {
								margin-left: -2rem;
							}
							.author__avatar-container {
								width: 1.5rem;
								height: 1.5rem;
							}
						}
					}
					.add-comment {
						margin-top: 1rem;
						label {
							display: block;
							font-size: 1rem; /* equivalent to text-md */
							font-weight: 500; /* equivalent to font-medium */
						}
						textarea {
							display: block;
							width: 100%;
							@media (min-width: 640px) {
								font-size: 0.875rem; /* equivalent to sm:text-sm */
								line-height: 1.5rem; /* equivalent to sm:leading-6 */
							}
						}
					}
					.add-comment:not(.comment__additionalreply) {
						margin-top: 0;
					}
				}
			}
			&.posts__post_newElement {
				--tw-ring-color: theme('colors.white');
			}
		}
	}
}

.posts__aside {
	ul {
		a {
			&.active {
				span {
					font-weight: bold;
				}
				&[data-filter='post'] {
					svg {
						fill: var(--feeds-post);
					}
				}
				&[data-filter='sharecare'] {
					svg {
						fill: var(--feeds-sharecare);
					}
				}
				&[data-filter='host'] {
					svg {
						fill: var(--feeds-host);
					}
				}
				&[data-filter='survey'] {
					svg {
						fill: var(--feeds-survey);
					}
				}
				&[data-filter='calendar'] {
					svg {
						fill: var(--feeds-calendar);
					}
				}
			}
			&[data-filter='host'] {
				margin-top: 2rem;
			}
		}
	}
}

// tooltip
.author__tooltip {
	&[role='tooltip'] {
		--tw-shadow: var(--shadow-feedMenu);
		--tw-shadow-colored: var(--shadow-feedMenu);
		@apply bg-white text-black rounded-2xl shadow;
		.author__name {
			@apply text-black;
			&:hover {
				text-decoration: none;
			}
		}
		&::before {
			@apply bg-white;
		}
		.button {
			@apply border-black bg-white text-black;
		}
	}
}

// fix bottom menu overlapping content on desktops
#content {
	margin-bottom: 7rem;
}

// color hacks
.content-block__description {
	@apply text-black;
}

// long text cut off
.content-block__description[data-component='accordion']
	[id*='panel'][aria-hidden='true']::after,
.content-block__description[data-component='accordion']
	[id*='panel'][aria-hidden='true']::before {
	content: none;
}

.content-block__description[data-component='accordion'] button {
	margin-block-start: 1rem;
}

// reactions icons

.posts__post_reactions_submenu {
	button {
		&[data-reaction]:not([data-reaction='']) {
			.reaction_icon {
				display: none;
			}
		}
	}
}

// safe classes for tailwind

.ring-feeds-notification {
	--tw-ring-color: rgba(var(--feeds-notification), 1);
}
.ring-feeds-host {
	--tw-ring-color: var(--feeds-host);
}
.ring-feeds-sharecare {
	--tw-ring-color: var(--feeds-sharecare);
}
.ring-feeds-survey {
	--tw-ring-color: var(--feeds-survey);
}
.ring-feeds-calendar {
	--tw-ring-color: var(--feeds-calendar);
}
.ring-feeds-post {
	--tw-ring-color: var(--feeds-post);
}
.bg-feeds-notification {
	background-color: rgba(var(--feeds-notification), 1);
}
.bg-feeds-formBody {
	background-color: var(--feeds-formBody);
}
.bg-feeds-bg {
	background-color: var(--feeds-bg);
}
.text-feeds-sidebar {
	color: var(--feeds-sidebar);
}
.fill-feeds-sidebar {
	fill: var(--feeds-sidebar);
}
.fill-feeds-host {
	fill: var(--feeds-host);
}
.fill-feeds-post {
	fill: var(--feeds-post);
}
.fill-feeds-sharecare {
	fill: var(--feeds-sharecare);
}
.fill-feeds-survey {
	fill: var(--feeds-survey);
}
.fill-feeds-calendar {
	fill: var(--feeds-calendar);
}
.border-feeds-host {
	border-color: var(--feeds-host);
}
.border-feeds-post {
	border-color: var(--feeds-post);
}
.border-feeds-sharecare {
	border-color: var(--feeds-sharecare);
}
.border-feeds-survey {
	border-color: var(--feeds-survey);
}
.border-feeds-calendar {
	border-color: var(--feeds-calendar);
}
.border-success {
	border-color: theme('colors.success');
}
.border-warning {
	border-color: theme('colors.warning');
}
.bg-success {
	background-color: theme('colors.success');
}
.bg-warning {
	background-color: theme('colors.warning');
}
.shadow-feedMenu {
	--tw-shadow: var(--shadow-feedMenu);
	--tw-shadow-colored: var(--shadow-feedMenu);
}
.shadow-feedBox {
	--tw-shadow: var(--shadow-feedBox);
	--tw-shadow-colored: var(--shadow-feedBox);
}
